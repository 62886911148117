import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Cleromancy = () => {
  return (
    <div>
      <Layout>
        <Head title="Cleromancy" />
        <p>
          <a href="https://en.wikipedia.org/wiki/Cleromancy">Wikipedia</a>
        </p>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/1.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/2.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/3.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/4.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/5.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/6.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/7.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/9.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/10.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/12.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/13.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/14.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1562132490/cleromancy/15.jpg"
          alt="Benji Friedman Berkeley Digital Art, JavaScript I Ching yarrow stalk simulation example"
        />
        <Link to="/media">
          <h3>Media</h3>
        </Link>
      </Layout>
    </div>
  );
};

export default Cleromancy;
